import { Controller } from '@hotwired/stimulus'
import { Popover }    from 'bootstrap'

export default class extends Controller {
  breadcrumbPopover = null;

  connect() {
    let link     = this.element.querySelector('#breadcrumb_popup')
    let template = this.element.querySelector('#breadcrumb_parent_template')

    if (link !== null && template !== null) {
      this.breadcrumbPopover = Popover.getOrCreateInstance(link, { html: true, sanitize: false, placement: 'bottom', content: template.innerHTML })

      link.addEventListener('show.bs.popover', () => {
        this.element.querySelectorAll('.breadcrumb_parents_show').forEach(parent => parent.classList.add('d-none'))
        this.element.querySelectorAll('.breadcrumb_parents_hide').forEach(parent => parent.classList.remove('d-none'))
      })

      link.addEventListener('hide.bs.popover', () => {
        this.element.querySelectorAll('.breadcrumb_parents_show').forEach(parent => parent.classList.remove('d-none'))
        this.element.querySelectorAll('.breadcrumb_parents_hide').forEach(parent => parent.classList.add('d-none'))
      })
    }
  }
}
